<template>
  <UiPopup
    :model-value="modelValue"
    title="Verify the phone number"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
  >
    <div>
      <p class="mb-6">
        Enter the 6-digit code displayed on your screen during the call from
        <span class="text-subhead-2">+14157234000</span> to complete verification. Once verified, your phone number will
        be marked as Verified in your profile.
      </p>
      <UiLoader v-if="loading"></UiLoader>
      <div v-else class="text-headline-3 inline-flex rounded-lg bg-primary-10 px-4 py-3 tracking-[5px]">{{ code }}</div>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()
const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: boolean
  phone: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})
const loading = ref(false)
const code = ref('')

onNuxtReady(async () => {
  loading.value = true
  try {
    const data = await useVerifyPhone({ phone: props.phone })
    code.value = data.code
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
})

watch(
  () => uiStore.getPhoneVerificationStatus,
  () => {
    emits('update:modelValue', false)
  }
)
</script>

<style scoped></style>
